<template>
  <b-card>

    <b-row>

      <!-- Org Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- Org Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="orgData.logo_url"
            :text="avatarText(orgData.name)"
            size="100px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ orgData.name }}
              </h4>
              <span class="card-text">{{ orgData.full_name }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{ name: 'orgs-edit', params: { id: orgData.id } }"
                variant="primary"
              >
                Edit
              </b-button>
              <b-button
                id="deleteTooltip" href="#"
                variant="outline-danger"
                class="ml-1"
              >
                Delete
              </b-button>
              <b-tooltip ref="deleteTooltip" target="deleteTooltip" title="Tooltip title" triggers="click">
                <div>Are you sure?</div> <b-link class="font-weight-bolder" @click="closeDeleteTooltip">Cancel</b-link> - <b-link class="text-danger font-weight-bolder" @click="deleteThis">Delete</b-link>
              </b-tooltip>
            </div>
          </div>
        </div>


      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Status</span>
            </th>
            <td class="pb-50 text-capitalize">
              <b-badge :variant="(orgData.enabled) ? 'success' : 'light-secondary'">
                {{ (orgData.enabled) ? 'Active' : 'Inactive' }}
              </b-badge>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Country</span>
            </th>
            <td class="pb-50">
              <img
                :src="`https://flagcdn.com/h20/${orgData.country}.png`"
                :srcset="`https://flagcdn.com/h40/${orgData.country}.png 2x,
                  https://flagcdn.com/h60/${orgData.country}.png 3x`"
                height="16"
                :alt="getCountryName(orgData.country)"
                class="mr-50"
              >
              <span>{{ getCountryName(orgData.country) }}</span>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Contact</span>
            </th>
            <td class="pb-50">
              {{ orgData.contact }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="BarChartIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Total Views</span>
            </th>
            <td>
              {{ orgData.all_analytics_count }}
            </td class="pb-50">
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="BarChart2Icon"
                class="mr-75"
              />
              <span class="font-weight-bold">Views (Previous 28 days)</span>
            </th>
            <td>
              {{ orgData.last_28_days_analytics_count }}
            </td class="pb-50">
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BBadge, BCard, BButton, BAvatar, BRow, BCol, BLink, BTooltip,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import useOrgsList from '../orgs-list/useOrgsList'
import countries from '@/countries-data'
import router from '@/router'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BBadge, BCard, BButton, BRow, BCol, BAvatar, BLink, BTooltip,
  },
  methods: {
    closeDeleteTooltip() {
      this.$refs.deleteTooltip.$emit('close')
    },
    getCountryName(countryCode) {
      return countries.find(country => country.value === countryCode).label
    },
  },
  props: {
    orgData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    // Use toast
    const toast = useToast()

    const { resolveOrgRoleVariant } = useOrgsList()

    const deleteThis = () => {
      let orgId = router.currentRoute.params.id

      store.dispatch('app/deleteOrg', { id: orgId })
        .then(() => {
          // Redirect to content items list
          router.push({ name: 'orgs' })
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error creating new user.',
              text: 'ERROR: ' + error.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    return {
      avatarText,
      deleteThis,
      resolveOrgRoleVariant,
    }
  },
}
</script>

<style>

</style>
