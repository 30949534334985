<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="orgData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching org data
      </h4>
      <div class="alert-body">
        No org found with this org id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'orgs'}"
        >
          Org List
        </b-link>
        for other orgs.
      </div>
    </b-alert>

    <template v-if="orgData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <org-view-org-info-card :org-data="orgData" />
          <org-view-org-timeline-card :org-data="orgData" />
        </b-col>
        <b-col
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >
          <permissions-groups-card
            :permissions="orgData.permissions"
            :can-view="orgData.can_view"
          />
        </b-col>
      </b-row>
    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import OrgViewOrgInfoCard from './OrgViewOrgInfoCard.vue'
import PermissionsGroupsCard from '../../../layouts/components/PermissionGroupsCard.vue'
import OrgViewOrgTimelineCard from './OrgViewOrgTimelineCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    OrgViewOrgInfoCard,
    PermissionsGroupsCard,
    OrgViewOrgTimelineCard,

  },
  setup() {
    const orgData = ref(null)

    store.dispatch('app/fetchOrg', { id: router.currentRoute.params.id })
      .then(response => { orgData.value = response.data.results[0] })
      .catch(error => {
        if (error.response.status === 404) {
          orgData.value = undefined
        }
      })

    return {
      orgData,
    }
  },
}
</script>

<style>

</style>
