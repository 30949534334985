<template>
  <b-card 
    :title="`${orgData.member_count} Users`" 
    :sub-title="seatsAvailableString" 
    no-body
  >

  <div class="p-2">
    <b-card-title>{{ `${orgData.member_count} Users` }}</b-card-title>
    <b-card-sub-title>{{ seatsAvailableString }}</b-card-sub-title>
  </div>
  
    <b-table 
      ref="refUserListTable" 
      class="position-relative" 
      :items="usersData" 
      responsive 
      :fields="tableColumns"
      primary-key="uuid" 
      :sort-by.sync="sortBy" 
      show-empty 
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
    >

      <!-- All column headers with no wrapping on text -->
      <template #head()="scope">
        <div class="text-nowrap">
          {{ scope.label }}
        </div>
      </template>

      <!-- All column headers with no wrapping on text -->
      <template #head(all_analytics_count)="scope">
        <div class="text-nowrap text-center">
          {{ scope.label }}
        </div>
      </template>

      <!-- All column headers with no wrapping on text -->
      <template #head(last_28_days_analytics_count)="scope">
        <div class="text-nowrap text-center">
          {{ scope.label }}
        </div>
      </template>

        <!-- Column: User -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.icon_url"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                :to="{ name: 'users-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'users-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </b-link>
            <b-badge
              v-if="data.item.enabled"
              pill
              :variant="`light-${resolveUserStatusVariant(data.item.enabled)}`"
              class="text-capitalize"
            >
              Active
            </b-badge>
            <b-badge
              v-else
              pill
              :variant="`light-${resolveUserStatusVariant(data.item.enabled)}`"
              class="text-capitalize"
            >
              Inactive
            </b-badge>
          </b-media>
        </template>

        <!-- Column: Date updated -->
        <template #cell(all_analytics_count)="data">
          <b-media
            vertical-align="center"
            class="text-center"
          >
            {{ data.item.all_analytics_count }}
          </b-media>
        </template>

        <!-- Column: Date updated -->
        <template #cell(last_28_days_analytics_count)="data">
          <b-media
            vertical-align="center"
            class="text-center"
          >
            {{ data.item.last_28_days_analytics_count }}
          </b-media>
        </template>



      </b-table>



  </b-card>
</template>




<script>
import {
  BTable, BCard, BCardTitle, BCardSubTitle, BMedia, BAvatar, BLink, BBadge,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import useUsersList from '@/views/user/users-list/useUsersList'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BTable,
    BCard,
    BCardTitle, 
    BCardSubTitle,
    BLink,
    BBadge,
    BMedia,
    BAvatar,
  },
  props: {
    orgData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    seatsAvailableString() {
      if (this.orgData.max_members === 0) return 'Unlimited seats available'

      const availableSeats = this.orgData.max_members - this.orgData.member_count
      return `${availableSeats < 0 ? 0 : availableSeats} seats remaining out of ${this?.orgData?.max_members} total`
    },
  },
  setup() {
    const usersData = ref(null)


    const tableColumns = [
      { key: 'name', label: 'User', sortable: true },
      { key: 'all_analytics_count', label: 'Total articles viewed',  sortable: true },
      { key: 'last_28_days_analytics_count', label: 'Articles viewed (Prev 28 days)', sortable: true },
      { key: 'last_active', label: 'Last active', sortable: true },
    ]

    const {
      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      isSortDirDesc,
      sortBy
    } = useUsersList()

    store.dispatch('app/fetchOrgMembers', { id: router.currentRoute.params.id, queryParams: { perPage: 100 } })
      .then(response => { usersData.value = response.data.results })
      .catch(error => {
        if (error.response.status === 404) {
          usersData.value = undefined
        }
      })

    return {
      usersData,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      avatarText,
      isSortDirDesc,
      sortBy,
      tableColumns
    }
  },
}
</script>

<style></style>
