<template>
  <b-card
    class="border"
    no-body
  >
    <b-card-header
      v-if="!single"
      class="pt-75 pb-25"
    >
      <h5>
        <feather-icon
          icon="EyeIcon"
          size="18"
        />
        Current Groups
      </h5>
    </b-card-header>
    <b-card-body>
      <p v-if="!single" class="mb-3 ">
        <template v-if="permissions.length">
          <b-badge
            v-for="permission in permissions"
            :key="permission.id"
            class="mr-1"
            variant="light-primary"
          >
            {{ permission.name }}
          </b-badge>
        </template>
        <em v-else>
          No groups assigned
        </em>
      </p>

      <h5 class="mb-0 pb-0">
        <feather-icon
          icon="LockIcon"
          size="18"
        />
        Active Permissions
      </h5>
    </b-card-body>

    <b-table
      striped
      responsive
      :items="permissionsData"
      class="mb-0"
    >

      <template #cell(content)="data">
        {{ data.value }}
      </template>
      <template #cell()="data">
        <b-form-checkbox
          disabled
          :checked="data.value"
        />
      </template>

    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BBadge, BTable, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import globalLists from '../../libs/global-lists'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BTable,
    BFormCheckbox,
  },
  props: {
    permissions: {
      type: Array,
      required: true,
    },
    canView: {
      type: Array,
      required: true,
    },
    single: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const {
      contentTypes,
    } = globalLists()

    const permissionsData = []
    contentTypes.forEach(contentType => {
      permissionsData.push({
        content: contentType.label,
        canView: props.canView.includes(contentType.value),
      })
    })

    return {
      permissionsData,
    }
  },
}
</script>

<style>

</style>
